<template>
	<v-form class="form" @submit.prevent="wich" lazy-validation ref="form">
		<v-row>
			<v-col cols="12" sm="6" md="6" class="column">
				<v-autocomplete
					v-model="form.client_id"
					:items="populatingRegisterPendency.client"
					filled
					label="Cliente"
					background-color="rgba(0, 0, 0, 0.03)"
					item-text="name"
					item-value="id"
					:rules="[v => !!v || 'Cliente não selecionado']"
					required
				/>
			</v-col>

			<v-col cols="12" sm="6" md="6" class="column">
				<v-autocomplete
					filled
					label="Projeto"
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.project_id"
					:items="populatingRegisterPendency.project"
					item-text="name"
					item-value="id"
					required
					:rules="[v => !!v || 'Projeto não selecionado']"
				/>
			</v-col>

			<v-col cols="12" sm="6" md="6" class="column">
				<v-select
					filled
					label="Tipo de Pêndencia"
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.pendency_id"
					:items="populatingRegisterPendency.pendency"
					item-text="name"
					item-value="id"
					required
					:rules="[v => !!v || 'Tipo de pendência não selecionado']"
				/>
			</v-col>

			<v-col cols="12" sm="6" md="6" class="column">
				<v-text-field
					label="Titulo"
					filled
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.title"
					required
					:rules="[v => !!v || 'Título não declarado']"
					counter
					maxlength="80"
				/>
			</v-col>

			<v-col cols="12" sm="6" md="12" class="column">
				<v-textarea
					filled
					no-resize
					name="input-7-4"
					label="Digite uma descrição para pendência..."
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.description"
					required
					:rules="[v => !!v || 'Descrição não declarada']"
					counter
					maxlength="400"
				/>
			</v-col>

			<v-col cols="12" sm="6" md="6" class="column">
				<v-autocomplete
					filled
					label="Responsável"
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.sponsor_id"
					:items="populatingRegisterPendency.sponsor"
					item-text="name"
					item-value="id"
					required
					:rules="[v => !!v || 'Responsável não selecionado']"
				/>
			</v-col>

			<v-col cols="12" sm="6" md="6" class="column">
				<v-text-field
					filled
					label="Solicitante"
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.requester"
					required
					:rules="[v => !!v || 'Solicitante não declarado']"
				/>
			</v-col>

			<v-col cols="12" sm="6" md="6" class="column">
				<v-select
					filled
					label="Tipo"
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.type_id"
					:items="populatingRegisterPendency.type"
					item-text="name"
					item-value="id"
					required
					:rules="[v => !!v || 'Tipo não selecionado']"
				/>
			</v-col>

			<v-col cols="12" sm="6" md="6" class="column">
				<v-select
					filled
					label="Prioridade"
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.priority_id"
					:items="populatingRegisterPendency.priority"
					item-text="name"
					item-value="id"
					required
					:rules="[v => !!v || 'Prioridade não selecionado']"
				/>
			</v-col>

			<v-col cols="12" sm="6" md="6" class="column">
				<v-autocomplete
					filled
					label="Status"
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.status_id"
					:items="populatingRegisterPendency.status"
					item-text="name"
					item-value="id"
					required
					:rules="[v => !!v || 'Status não selecionado']"
				/>
			</v-col>
			<!-- <v-col cols="12" sm="6" md="6" class="column">
				<v-autocomplete
					filled
					label="Pendência Origem"
					background-color="rgba(0, 0, 0, 0.03)"
					v-model="form.pendency_origin"
					required
				/>
			</v-col> -->
			<v-col cols="12" sm="6" md="12" class="column">
				<v-btn color="primary" depressed type="submit"> Salvar </v-btn>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import overlayDrawer from '@/mixins/overlayDrawer'

export default {
	// props: ['pendency', 'edit'],
	props: {
		pendency: Object,
		edit: {
			type: Boolean,
			default: false,
		},
		id: Number,
	},
	data: () => ({
		form: {
			client_id: '',
			project_id: '',
			pendency_id: '',
			description: '',
			sponsor_id: '',
			requester: '',
			type_id: '',
			title: '',
			priority_id: '',
			status_id: '',
			// pendency_origin: '',
		},
	}),
	mixins: [overlayDrawer],
	computed: {
		...mapGetters({
			populatingRegisterPendency: 'pendencies/populatingRegisterPendency',
		}),
	},
	async created() {
		await this.$store.dispatch('pendencies/populatingRegisterPendency')
		this.populateTextfield()
	},
	methods: {
		populateTextfield() {
			const arr = Object.keys(this.form)
			arr.map(item => {
				this.form[item] = this.pendency[item]
			})
		},
		async create() {
			if (this.$refs.form.validate()) {
				try {
					await this.$store.dispatch('pendencies/createPendency', this.form)

					this.$store.dispatch('setSnackbar', {
						status: true,
						message: 'Pendência cadastrada com sucesso.',
					})
					this.clearFields()
					this.$router.push({
						name: 'details-pendency',
						params: {
							id: 113866,
						},
					})
				} catch (e) {
					throw e
				}
			}
		},
		async update() {
			if (this.$refs.form.validate()) {
				try {
					await this.$store.dispatch('pendencies/editPendency', {
						id: this.id,
						...this.form,
					})

					this.$store.dispatch('setSnackbar', {
						status: true,
						message: 'Pendência atualizada com sucesso.',
					})
				} catch (e) {
					throw e
				}
			}
		},
		wich() {
			if (this.edit) return this.update()
			this.create()
		},
		clearFields() {
			const arr = Object.keys(this.form)
			arr.map(item => {
				this.form[item] = ''
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.form {
	padding: 20px;
	.column {
		display: flex;
		justify-content: flex-end;
		padding: 0 12px;
	}
}
</style>
